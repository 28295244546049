import React, { useContext, useState, Fragment, useEffect } from "react"
import Layout from "../components/layout"
import { StateContext } from "../context/stateProvider"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid"
import { NavigationContext } from "../context/Navigation"
import CourseCard from "../components/CourseCard"
import courseSvc from "../services/Course"
import Loader from "../components/Loader"
import { englishStarCourseId } from "../../config"
import { sortEnglishCourse } from "./home-pages"
const { coursesByCategories, coursesByField } = require("../data.json")

function Category({ pageContext }) {
  const { category } = pageContext
  const { courseName, Category, setCourseName, setCategory } =
    useContext(StateContext)
  const { setPath } = useContext(NavigationContext)

  // Courses of this category (up-to-date)
  const [courses, setCourses] = useState(sortEnglishCourse(coursesByField))

  // Bootstrap
  useEffect(() => {
    console.log("Effect Triggered")
    setCategory(category)

    courseSvc
      .fetchAllCourses()
      .catch(err => {
        console.error(err)
        return []
      })
      .then(courses => setCourses(sortEnglishCourse(courses)))
  }, [])

  // All categories
  const handleSearch = e => {
    let value = e.target.value.toLowerCase()
    setCourseName(value)
  }
  const changeCategory = e => {
    setCategory(e)
    setPath(`/courses/${e.id}`)
  }
  const searchTerms = courseName.split(" ").map(e => e.trim())

  const filteredData = (courses || []).filter(item => {
    let exists = false
    const { displayName, shortName, categoryId } = item
    searchTerms.map(term => {
      const compareString = `${displayName} ${shortName} ${categoryId}`
      if (compareString.toLowerCase().includes(term)) exists = true
    })
    return exists
  })

  return (
    <Layout>
      <div className="content mx-auto">
        <div className="flex flex-col md:flex-row justify-between pb-12 pt-36 px-5">
          <input
            type="text"
            onChange={handleSearch}
            placeholder="Search Courses"
            value={courseName}
            className="bg-gray-100 text text-md px-4 py-3 rounded-lg outline w-full md:w-3/5 "
          />

          <div className="w-full lg:w-1/3 z-10">
            {/* <Listbox value={selected} onChange={setSelected}> */}
            <Listbox value={Category} onChange={changeCategory}>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full py-3 pl-2 pr-6 text-left bg-white cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500">
                  <span className="block truncate text-md text-gray-500">
                    {/* {selected?.categoryName} */}
                    {Category?.categoryName}
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-1 pointer-events-none">
                    <SelectorIcon
                      className="w-6 h-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Listbox.Options className="absolute -left-16 select-width py-1 mt-1 overflow-auto scroll text-sm bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {coursesByCategories?.map(category => {
                      const { categoryName, id } = category

                      return (
                        <Listbox.Option
                          // key={person.id}
                          key={id}
                          className={({ active }) =>
                            `${
                              active
                                ? "text-yellow-900 bg-yellow-100"
                                : "text-gray-900"
                            }
                          cursor-default select-none relative py-2 pl-10 pr-4`
                          }
                          value={category}
                          // value={person?.categoryName}
                        >
                          {/* {({ selected, active }) => ( */}
                          {({ selected, active }) => (
                            <>
                              <span
                                className={`${
                                  // selected ? "font-medium" : "font-normal"
                                  selected ? "font-medium" : "font-normal"
                                } block truncate text-xl pl-4`}
                                dangerouslySetInnerHTML={{
                                  __html: categoryName,
                                }}
                              >
                                {}
                                {/* {categoryName} */}
                                {/* {person.categoryName} */}
                              </span>
                              {/* {selected ? ( */}
                              {selected ? (
                                <span
                                  className={`${
                                    active
                                      ? "text-yellow-600"
                                      : "text-yellow-600"
                                  }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                                >
                                  <CheckIcon
                                    className="w-8 h-8"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      )
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        </div>

        {!filteredData && (
          <div className="w-full flex justify-center p-4">
            <Loader style={{ width: "130px" }} />
          </div>
        )}
        {filteredData && filteredData.length === 0 && (
          <h2>No Courses available</h2>
        )}
        {filteredData && filteredData.length > 0 && (
          <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-2 gap-y-6">
            {filteredData.map(obj => (
              <CourseCard data={obj} key={obj.courseId} />
            ))}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Category
